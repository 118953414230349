import { useStaticQuery, graphql } from 'gatsby';
import _get from 'lodash/get';
import { simpleFormat, getYouTubeID } from '../utils';
import { FeaturedImage } from '../types';

export type QueryResponse = {
  allWpBeautyShare: {
    edges: {
      node: {
        id: string;
        title: string;
        date: string;
        featuredImage: {
          node: FeaturedImage;
        };
        acf: {
          article: (
            | {
                fieldGroupName: 'beauty_share_Acf_Article_TextSubTitle';
                textSubTitle: string;
              }
            | {
                fieldGroupName: 'beauty_share_Acf_Article_Text';
                text: string;
              }
            | {
                fieldGroupName: 'beauty_share_Acf_Article_SinglePicture';
                pictureTitle: string;
                pictureTitleOpen: boolean;
                picture: FeaturedImage;
              }
            | {
                fieldGroupName: 'beauty_share_Acf_Article_MultiplePictures';
                picturesShareTitle: string;
                picturesShareTitleOpen: boolean;
                picturesFt: (
                  {
                    fieldGroupName: 'pictures_ft';
                    pictureTitleOpenFt: boolean;
                    picturesTitleFt: string;
                    picturesFt: FeaturedImage;
                  }
                )
                picturesSt: (
                  {
                    fieldGroupName: 'pictures_st';
                    pictureTitleOpenSt: boolean;
                    picturesTitleSt: string;
                    picturesSt: FeaturedImage;
                  }
                )
              }
            | {
                fieldGroupName: 'beauty_share_Acf_Article_MediaLink';
                mediaLink: string;
              }
          )[];
        };
      };
    }[];
  };
};

export const useBeautyQueries = (id?: String) => {
  const { allWpBeautyShare } = useStaticQuery<QueryResponse>(graphql`
  {
    allWpBeautyShare(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          id
          title
          date(formatString: "YYYY.MM.DD")
          ...BeautyImage
          acf {
            article {
              ... on WpBeauty_share_Acf_Article_TextSubTitle {
                fieldGroupName
                textSubTitle
              }
              ... on WpBeauty_share_Acf_Article_Text {
                fieldGroupName
                text
              }
              ... on WpBeauty_share_Acf_Article_SinglePicture {
                fieldGroupName
                pictureTitle
                pictureTitleOpen
                picture {
                  ...BeautyImageSinglePicture
                }
              }
              ... on WpBeauty_share_Acf_Article_MultiplePictures {
                fieldGroupName
                picturesShareTitle
                picturesShareTitleOpen
                picturesFt {
                  fieldGroupName
                  pictureTitleOpenFt
                  picturesTitleFt
                  picturesFt {
                    ...BeautyImageSinglePicture
                  }
                }
                picturesSt {
                  fieldGroupName
                  pictureTitleOpenSt
                  picturesTitleSt
                  picturesSt {
                    ...BeautyImageSinglePicture
                  }
                }
              }
              ... on WpBeauty_share_Acf_Article_MediaLink {
                fieldGroupName
                mediaLink
              }
            }
          }
        }
      }
    }
  }
  `);

  return allWpBeautyShare.edges.filter(function (node) {
    if (id === '') {
      return true;
    }
    else if (id === node.node.id) {
      return true;
    }
    return false;
  }).map(({ node }) => {
    const {
      id,
      title,
      date,
      acf: { article },
      featuredImage
    } = node;

    const contents = article.map((beautyContent) => {
      if (beautyContent.fieldGroupName === 'beauty_share_Acf_Article_TextSubTitle') {
        return {
          type: 'subTitle',
          subTitle: beautyContent.textSubTitle
        };
      }
      else if (beautyContent.fieldGroupName === 'beauty_share_Acf_Article_Text') {
        return {
          type: 'text',
          text: beautyContent.text
        };
      }
      else if (beautyContent.fieldGroupName === 'beauty_share_Acf_Article_SinglePicture') {
        return {
          type: 'singlePicture',
          pictureTitle: (beautyContent.pictureTitleOpen === true) ? beautyContent.pictureTitle : '',
          thumbnail: {
            fluid: beautyContent.picture.localFile.childImageSharp.fluid,
          }
        };
      }
      else if (beautyContent.fieldGroupName === 'beauty_share_Acf_Article_MultiplePictures') {
        return {
          type: 'multiplePictures',
          picturesShareTitle: (beautyContent.picturesShareTitleOpen === true) ? beautyContent.picturesShareTitle : '',
          picturesFt: {
            picturesTitleFt: (beautyContent.picturesFt.pictureTitleOpenFt === true) ? beautyContent.picturesFt.picturesTitleFt : '',
            thumbnail: {
              title: beautyContent.picturesFt.picturesFt.title,
              alt: beautyContent.picturesFt.picturesFt.altText,
              fluid: beautyContent.picturesFt.picturesFt.localFile.childImageSharp.fluid,
            },
          },
          picturesSt: {
            picturesTitleSt: (beautyContent.picturesSt.pictureTitleOpenSt === true) ? beautyContent.picturesSt.picturesTitleSt : '',
            thumbnail: {
              title: beautyContent.picturesSt.picturesSt.title,
              alt: beautyContent.picturesSt.picturesSt.altText,
              fluid: beautyContent.picturesSt.picturesSt.localFile.childImageSharp.fluid,
            },
          },
        };
      }
      else if (beautyContent.fieldGroupName === 'beauty_share_Acf_Article_MediaLink') {
        return {
          type: 'mediaLink',
          mediaLink: getYouTubeID(beautyContent.mediaLink)
        };
      }
    });

    const thumbnail = {
      title: featuredImage.node.title,
      alt: featuredImage.node.altText,
      fluid: featuredImage.node.localFile.childImageSharp.fluid,
    };

    return {
      id,
      title,
      date,
      thumbnail,
      contents
    }
  });
};
