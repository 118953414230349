import React, { ReactNode } from 'react';
import {graphql} from 'gatsby';
import { Box, Flex, Heading, Text, Button } from 'rebass/styled-components';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import { useBeautyQueries } from '../queries/useBeautyQueries';
import '../styles/single_post.css';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Img from 'gatsby-image';

const singlePost = (pageContext: any) => {
  const wpBeautyShare = pageContext.data.wpBeautyShare;
  const beautyShareContents = useBeautyQueries(pageContext.pageContext.id)[0];
  return (
    <Layout>
      { wpBeautyShare.seo !== null && (<Seo post={wpBeautyShare} />)}
      <Container>
          <Text as="h6">{beautyShareContents.date}</Text>
          <Text as="h1" mt={16} mb={40}>{beautyShareContents.title}</Text>

          {/* Contents. */}
          {beautyShareContents.contents.map(element => {
            if (element) {
              // text.
              if (element.type === 'text') {
                return (
                  <Text 
                    as="h4" 
                    mt={32}
                    mb={32}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{element.text}
                  </Text>
                )
              }

              // subTitle.
              if (element.type === 'subTitle') {
                return (
                  <Text as="h2" mt={64} mb={32}>{element.subTitle}</Text>
                )
              }

              // singlePicture.
              if (element.type === 'singlePicture' && element.thumbnail) {
                return (
                  <Box>
                    <Img {...element.thumbnail}/>
                    <Text 
                      as="h5" 
                      mt={(element.pictureTitle !== '') ? 19 : 0} 
                      color="rgb(90, 90, 90);">
                      {element.pictureTitle}
                    </Text>
                    </Box>
                )
              }

              // multiplePictures.
              if (element.type === 'multiplePictures') {
                // picturesFt & picturesSt might be undefined.
                if (element.picturesFt && element.picturesSt) {
                  return (
                    <Box>
                      <Flex
                        className="multiple-pictures"
                      >
                        <Box className="pic1">
                          <Img {...element.picturesFt.thumbnail}/>
                          <Text 
                            as="h5" 
                            mt={(element.picturesFt.picturesTitleFt !== '') ? 19 : 0} 
                            color="rgb(90, 90, 90);">
                            {element.picturesFt.picturesTitleFt}
                          </Text>
                        </Box>
                        <Box>
                          <Img {...element.picturesSt.thumbnail}/>
                          <Text 
                            as="h5" 
                            mt={(element.picturesSt.picturesTitleSt !== '') ? 19 : 0} 
                            color="rgb(90, 90, 90);">
                            {element.picturesSt.picturesTitleSt}
                          </Text>
                        </Box>
                      </Flex>
                      <Text 
                        as="h5" 
                        mt={(element.picturesShareTitle !== '') ? 19 : 0} 
                        color="rgb(90, 90, 90);">
                        {element.picturesShareTitle}
                      </Text>
                    </Box>
                  )
                }
              }

              // mediaLinks
              if (element.type === 'mediaLink') {
                if(element.mediaLink) {
                  return (
                    <Box className="media-link" >
                      <LiteYoutubeEmbed id={element.mediaLink}></LiteYoutubeEmbed>
                    </Box>
                  )
                }
              }
            }
          })}

        <Box 
          sx={{ 
            textAlign: 'center',
            marginTop: '120px',
            marginBottom: '120px',
            '@media screen and (max-width: 414px)': {
              marginTop: '56px',
              marginBottom: '104px',
            }
          }}
        >
          <Link to="/beautyshare">
            <Button as="span" variant="outline" fontSize={[16, 18]} fontWeight="bold" backgroundColor="#FFFFF9">
              回上一頁
            </Button>
          </Link>
        </Box>
      </Container>
    </Layout>
  );
};

const Container = (props: { children: ReactNode }) => (
  <Box
    {...props}
    ml="auto"
    mr="auto"
    mt={168}
    mb={120}
    width={1208}
    sx={{
      '@media screen and (max-width: 639px)': {
        ml:"auto",
        mr:"auto",
        mt:"80px",
        mb:"104px",
        width:"336px",
      }
    }}
  />
);


export default singlePost;

export const pageQuery = graphql`
    query GET_PAGE($id: String!) {
      wpBeautyShare(id: { eq: $id }) {
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
        } 
      }
    }
`;